import { AppContext } from "../Store";
import {
  FlatpickrInput,
  fixBlankInputOnSingleDate,
  onDateRangeChange,
} from "../utils";
import {
  DANNO_URL,
  editDepartureNote,
  getInventory,
  getInventoryTotals,
  makeDepartureNote,
  updateDeparture,
  updateDepartureStatus,
} from "../api";
import {
  AdminGetInventoryRes,
  AdminGetInventoryTotalsRes,
  DepartureChangeDO,
  DepartureChangeDO_CapacityField,
  DepartureChangeDO_Field,
  DepartureDO_Status,
  DepartureNoteDO,
  LoginDO_ID,
  PermissionDO_ID,
  SeriesDO,
  TourYearDO,
  arrOfSize,
  arrUnique,
  canChangeDepartureStatusTo,
  departureStatus,
  hasPermission,
  nowInCT,
  priceNameFrom,
  pricesByValue,
  seriesCodeFrom,
  seriesIdFromDate,
  titleCase,
  tourNumber,
  yearFromDate,
} from "data-model";
import {
  DateAndInitials,
  ErrorMessage,
  ExternalLink,
  Input,
  ModalDispatch,
  Multiselect,
  SVG,
  Select,
  useErrors,
  useModal,
} from "react-components";
import { DBProxy } from "jsongo";
import {
  CSSProperties,
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import flatpickr from "flatpickr";
import clsx from "clsx";
import { DateTime } from "luxon";

const gridStyle: CSSProperties = {
  gridTemplateColumns:
    "38px 95px 98px 53px 112px 36px 41px repeat(3, 34px) 38px 36px 41px 35px 58px 60px 85px 86px 1fr 104px",
};
const whiteCell = "has-background-white padding-1";
const centeredCell = "is-flex is-align-items-center is-justify-content-center";
const badgeStyle: CSSProperties = { top: 2, right: 3 };

const MAX_ROOMS = 30;
const MAX_COACH_SEATS = 50;
const MAX_COACH_SIZE = 54;

const Inventory = () => {
  const [{ db, user }] = useContext(AppContext);
  if (!db || !user) throw new Error("unreachable");

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(true);

  const setModal = useModal();

  const [inventory, setInventory] = useState<AdminGetInventoryRes>([]);

  const allowedToEdit = hasPermission(user, PermissionDO_ID.EditInventory, db);

  let avgPaxSold = 0;

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { rowIdx } = e.currentTarget.dataset as { rowIdx: string };
    const row = inventory[+rowIdx];
    const { series, departedAt } = row.departure;

    const status = e.currentTarget.value as DepartureDO_Status;

    setIsLoading(true);
    catchErrors(
      async () => {
        const [departure, change] = await updateDepartureStatus({
          series,
          departedAt,
          status,
        });

        setInventory((inventory) =>
          inventory.map((row, idx) => {
            if (idx === +rowIdx)
              return {
                ...row,
                departure: {
                  ...departure,
                  changes: [change, ...row.departure.changes],
                  notes: row.departure.notes,
                },
              };
            return row;
          })
        );
      },
      () => setIsLoading(false)
    );
  };

  const handleCapacityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { rowIdx, field } = e.currentTarget.dataset as {
      rowIdx: string;
      field: DepartureChangeDO_CapacityField;
    };
    const row = inventory[+rowIdx];
    const { series, departedAt } = row.departure;

    const { value } = e.currentTarget;

    setIsLoading(true);
    catchErrors(
      async () => {
        const change = await updateDeparture({
          series,
          departedAt,
          field,
          value,
        });

        setInventory((inventory) =>
          inventory.map((row, idx) => {
            if (idx === +rowIdx)
              return {
                ...row,
                departure: {
                  ...row.departure,
                  [field]: value === "" ? undefined : +value,
                  changes: [change, ...row.departure.changes],
                },
              };
            return row;
          })
        );
      },
      () => setIsLoading(false)
    );
  };

  const handleNoteHistory = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { rowIdx } = e.currentTarget.dataset as { rowIdx: string };
    const row = inventory[+rowIdx];
    setModal({
      body: (
        <NoteHistory
          departedAt={row.departure.departedAt}
          isLocked={!allowedToEdit}
          loginId={user._id}
          notes={row.departure.notes}
          onAdd={(note) => handleNoteChange(+rowIdx, note, true)}
          onEdit={(note) => handleNoteChange(+rowIdx, note, false)}
          series={row.departure.series}
        />
      ),
      isOpen: true,
      title: (
        <h1 className="margin-bottom-0">
          Tour Departure Notes –{" "}
          {tourNumber(row.departure.series, row.departure.departedAt)}
        </h1>
      ),
    });
  };

  const handleNoteChange = (
    rowIdx: number,
    note: DepartureNoteDO,
    isNew: boolean
  ) => {
    setInventory((inventory) =>
      inventory.map((row, idx) => {
        if (idx === rowIdx) {
          const notes = isNew
            ? [note, ...row.departure.notes]
            : [note, ...row.departure.notes.filter((n) => n.id !== note.id)];
          return {
            ...row,
            departure: {
              ...row.departure,
              notes,
            },
          };
        }
        return row;
      })
    );
  };

  const handleChangeLog = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { rowIdx } = e.currentTarget.dataset as { rowIdx: string };
    const row = inventory[+rowIdx];
    setModal({
      body: <ChangeLog changes={row.departure.changes} />,
      isOpen: true,
      title: (
        <h1 className="margin-bottom-0">
          Change Log –{" "}
          {tourNumber(row.departure.series, row.departure.departedAt)}
        </h1>
      ),
    });
  };

  return (
    <main className="padding-top-2 padding-bottom-4 margin-x-auto">
      <Filters
        catchErrors={catchErrors}
        db={db}
        errors={errors}
        isLoading={isLoading}
        setInventory={setInventory}
        setIsLoading={setIsLoading}
        setModal={setModal}
      />

      <div
        className="is-grid is-gap-1px has-background-gray has-border-gray is-sticky is-top-0 is-z-index-1"
        style={gridStyle}
      >
        <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
          #
        </strong>
        <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
          Departure
        </strong>
        <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
          Tour Number
        </strong>
        <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
          Twin $
        </strong>
        <strong
          className={`${whiteCell} ${centeredCell} is-grid-row-span-2 margin-right-1px`}
        >
          Status
        </strong>
        <strong
          className={`${whiteCell} has-text-centered is-grid-column-span-6 margin-right-1px`}
        >
          Sold
        </strong>
        <strong
          className={`${whiteCell} has-text-centered is-grid-column-span-3 margin-right-1px`}
        >
          Remaining
        </strong>
        <strong
          className={`${whiteCell} has-text-centered is-grid-column-span-4 margin-right-1px`}
        >
          Capacity
        </strong>
        <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
          Tour Departure Notes
        </strong>
        <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
          Last Changed
        </strong>

        <strong className={whiteCell}>Pax</strong>
        <strong className={whiteCell}>Rms</strong>
        <strong className={whiteCell}>Sgl</strong>
        <strong className={whiteCell}>Dbl</strong>
        <strong className={whiteCell}>Tpl</strong>
        <strong className={`${whiteCell} margin-right-1px`}>Qds</strong>

        <strong className={whiteCell}>Pax</strong>
        <strong className={whiteCell}>Rms</strong>
        <strong className={`${whiteCell} margin-right-1px`}>Sgl</strong>

        <strong className={whiteCell}>Rooms</strong>
        <strong className={whiteCell}>Singles</strong>
        <strong className={whiteCell}>Coach Max</strong>
        <strong className={`${whiteCell} margin-right-1px`}>Coach Size</strong>
      </div>
      <div
        className="is-grid is-gap-1px has-background-gray has-border-x-gray has-border-bottom-gray"
        style={gridStyle}
      >
        {inventory.map((row, idx) => {
          const { departure, occupancy } = row;
          const series = db.series.findByIdOrFail(
            seriesIdFromDate(departure.series, departure.departedAt)
          ) as SeriesDO;
          const status = departureStatus(departure, series);

          // Capacity
          const roomCap = departure.roomCap ?? series.roomCap;
          const singleMax = departure.singleMax ?? series.singleMax;
          const busMax = departure.busMax ?? series.busMax;
          const busSize = departure.busSize ?? series.busSize;

          const note: DepartureNoteDO | undefined = departure.notes[0];
          const change: DepartureChangeDO | undefined = departure.changes[0];

          const tourYear = db.tourYear.findByIdOrFail(
            series.tourYear_id
          ) as TourYearDO;
          const statusOptions = [
            status,
            ...canChangeDepartureStatusTo(
              departure,
              series,
              tourYear.timezoneEnd
            ),
          ].sort();
          const isLocked = statusOptions.length === 1 || !allowedToEdit;

          const priceName = priceNameFrom(departure.price);
          const { prices, taxesAndFees } = pricesByValue(tourYear, priceName);
          const dblPrice =
            prices.double !== null && taxesAndFees.double !== null
              ? prices.double + taxesAndFees.double
              : "TBA";

          avgPaxSold += occupancy.pax;

          return (
            <Fragment key={idx}>
              <span className={`${whiteCell} has-text-right has-text-mid-gray`}>
                {idx + 1}
              </span>
              <span className={whiteCell}>
                {DateTime.fromISO(departure.departedAt).toFormat("ccc, MMM dd")}
              </span>
              <ExternalLink
                target="_blank"
                href={`${DANNO_URL}/manage-booking?series=${departure.series}&date=${departure.departedAt}`}
                className={whiteCell}
              >
                {tourNumber(departure.series, departure.departedAt)}
              </ExternalLink>
              <span className={`${whiteCell} has-text-right`}>{dblPrice}</span>
              <Select
                className="is-borderless is-square is-size-6 has-text-weight-normal is-height-auto padding-1"
                parentClassName="margin-right-1px"
                value={status}
                onChange={handleStatusChange}
                data-row-idx={idx}
                disabled={isLocked}
              >
                {statusOptions.map((status) => (
                  <option key={status} value={status}>
                    {titleCase(status, "-", "-")}
                  </option>
                ))}
              </Select>

              <strong
                className={clsx(
                  whiteCell,
                  busMax < occupancy.pax && "has-text-red"
                )}
              >
                {occupancy.pax}
              </strong>
              <span
                className={clsx(
                  whiteCell,
                  roomCap < occupancy.rooms && "has-text-red"
                )}
              >
                {occupancy.rooms}
              </span>
              <span
                className={clsx(
                  whiteCell,
                  singleMax !== undefined &&
                    singleMax < occupancy.singles &&
                    "has-text-red"
                )}
              >
                {occupancy.singles}
              </span>
              <span className={whiteCell}>{occupancy.doubles}</span>
              <span className={whiteCell}>{occupancy.triples}</span>
              <span className={`${whiteCell} margin-right-1px`}>
                {occupancy.quads}
              </span>

              {[
                busMax - occupancy.pax,
                roomCap - occupancy.rooms,
                singleMax !== undefined ? singleMax - occupancy.singles : "",
              ].map((value, idx) => (
                <span
                  key={idx}
                  className={clsx(
                    whiteCell,
                    idx === 2 && "margin-right-1px",
                    typeof value !== "string" && value < 0 && "has-text-red"
                  )}
                >
                  {value}
                </span>
              ))}

              <CapacitySelector
                disabled={isLocked}
                field="roomCap"
                onChange={handleCapacityChange}
                range={MAX_ROOMS}
                rowIdx={idx}
                value={roomCap}
              />
              <CapacitySelector
                allowEmpty={series.singleMax === undefined}
                disabled={isLocked}
                field="singleMax"
                onChange={handleCapacityChange}
                range={roomCap}
                rowIdx={idx}
                value={singleMax}
              />
              <CapacitySelector
                disabled={isLocked}
                field="busMax"
                onChange={handleCapacityChange}
                range={MAX_COACH_SEATS}
                rowIdx={idx}
                value={busMax}
              />
              <CapacitySelector
                disabled={isLocked}
                field="busSize"
                onChange={handleCapacityChange}
                parentClassName="margin-right-1px"
                range={MAX_COACH_SIZE}
                rowIdx={idx}
                value={busSize}
              />

              <button
                className={`${whiteCell} is-borderless has-text-left has-text-blue has-ellipsis is-relative`}
                onClick={handleNoteHistory}
                data-row-idx={idx}
              >
                {note && (
                  <>
                    <DateAndInitials
                      dateIso={note.updatedAt}
                      loginId={note.loginId}
                    />{" "}
                    {note.body}
                    <small className="is-absolute" style={badgeStyle}>
                      {departure.notes.length}
                    </small>
                  </>
                )}
              </button>

              {change ? (
                <button
                  className={`${whiteCell} is-borderless has-text-left has-text-blue has-ellipsis is-relative`}
                  onClick={handleChangeLog}
                  data-row-idx={idx}
                >
                  <DateAndInitials
                    dateIso={change.createdAt}
                    loginId={change.loginId}
                  />
                  <small className="is-absolute" style={badgeStyle}>
                    {departure.changes.length}
                  </small>
                </button>
              ) : (
                <span className={whiteCell} />
              )}
            </Fragment>
          );
        })}
      </div>
      <div className="is-grid has-border-x-white is-gap-1px" style={gridStyle}>
        <strong className="padding-1" style={{ gridColumn: "6 / 7" }}>
          {inventory.length && Math.round(avgPaxSold / inventory.length)}
        </strong>
      </div>
    </main>
  );
};

export { Inventory };

const allStatuses = [
  DepartureDO_Status.cancelled,
  DepartureDO_Status.closed,
  DepartureDO_Status.closedAuto,
  DepartureDO_Status.departed,
  DepartureDO_Status.open,
];

const statusLabels = allStatuses.map((s) => titleCase(s, "-", "-"));

const dateDefaults = () => {
  const now = nowInCT();
  const today = now.toISODate();
  return {
    departedAtFrom: today,
    departedAtTo: now.plus({ days: 6 }).toISODate(),
  };
};

interface FiltersProps {
  catchErrors: ReturnType<typeof useErrors>[1];
  db: DBProxy;
  errors: ReturnType<typeof useErrors>[0];
  isLoading: boolean;
  setInventory: (inventory: AdminGetInventoryRes) => void;
  setIsLoading: (isLoading: boolean) => void;
  setModal: ModalDispatch;
}

const Filters: FC<FiltersProps> = ({
  catchErrors,
  db,
  errors,
  isLoading,
  setInventory,
  setIsLoading,
  setModal,
}) => {
  const departedAtRef = useRef<FlatpickrInput>(null);
  const changedAtRef = useRef<FlatpickrInput>(null);

  const allSeries = db.series.docs() as SeriesDO[];
  const uniqueCodes = arrUnique(allSeries.map(seriesCodeFrom).sort());

  const [series, setSeries] = useState(uniqueCodes);
  const [statuses, setStatuses] = useState(allStatuses);
  const defaults = dateDefaults();
  const [departedAtFrom, setDepartedAtFrom] = useState(defaults.departedAtFrom);
  const [departedAtTo, setDepartedAtTo] = useState(defaults.departedAtTo);
  const [changedAtFrom, setChangedAtFrom] = useState("");
  const [changedAtTo, setChangedAtTo] = useState("");

  const handleReset = () => {
    const defaults = dateDefaults();
    setSeries(uniqueCodes);
    setStatuses(allStatuses);
    setDepartedAtFrom(defaults.departedAtFrom);
    setDepartedAtTo(defaults.departedAtTo);
    setChangedAtFrom("");
    setChangedAtTo("");

    departedAtRef.current!._flatpickr.setDate([
      defaults.departedAtFrom,
      defaults.departedAtTo,
    ]);
    changedAtRef.current!._flatpickr.setDate(["", ""]);
  };

  const handleSearch = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const inventory = await getInventory({
          series: series.join(","),
          ...(statuses.length !== allStatuses.length && {
            status: statuses.join(","),
          }),
          departedAtFrom,
          departedAtTo,
          ...(changedAtFrom && changedAtTo && { changedAtFrom, changedAtTo }),
        });
        setInventory(inventory);
      },
      () => setIsLoading(false)
    );
  };

  const handleTotals = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const year = yearFromDate(departedAtFrom).toString();
        const totals = await getInventoryTotals({
          series: series.join(","),
          year,
        });
        setModal({
          body: <Totals totals={totals} />,
          isOpen: true,
          title: <h1 className="margin-bottom-0">{year} Totals</h1>,
        });
      },
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    handleSearch();

    flatpickr(departedAtRef.current!, {
      mode: "range",
      defaultDate: [departedAtFrom, departedAtTo],
      onClose: fixBlankInputOnSingleDate,
      onChange: onDateRangeChange(setDepartedAtFrom, setDepartedAtTo),
    });

    flatpickr(changedAtRef.current!, {
      mode: "range",
      onClose: fixBlankInputOnSingleDate,
      onChange: onDateRangeChange(setChangedAtFrom, setChangedAtTo),
    });
  }, []);

  return (
    <>
      <div
        className="is-grid is-column-gap-2 is-row-gap-0-pt-5 is-align-items-center margin-bottom-3"
        style={{
          gridTemplateColumns:
            "auto 80px auto 140px repeat(4, auto) 1fr auto auto",
        }}
      >
        {/* Row 1 */}

        <div className="is-flex" style={{ gridColumn: "6 / 7" }}>
          {departureShortcuts().map(([from, to, label]) => (
            <button
              key={label}
              className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
              onClick={() => {
                departedAtRef.current!._flatpickr.setDate([from, to], true);
              }}
            >
              {label}
            </button>
          ))}
        </div>

        <div className="is-flex" style={{ gridColumn: "8 / 9" }}>
          <button
            className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
            onClick={() => {
              const today = nowInCT().toISODate();
              changedAtRef.current!._flatpickr.setDate([today, today], true);
            }}
          >
            Today
          </button>
          <button
            className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
            onClick={() => {
              const yesterday = nowInCT().minus({ days: 1 }).toISODate();
              changedAtRef.current!._flatpickr.setDate(
                [yesterday, yesterday],
                true
              );
            }}
          >
            Yesterday
          </button>
          <button
            className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
            onClick={() => {
              const last7 = nowInCT().minus({ days: 6 }).toISODate();
              const today = nowInCT().toISODate();
              changedAtRef.current!._flatpickr.setDate([last7, today], true);
            }}
          >
            7 Days
          </button>
          <button
            className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
            onClick={() => {
              const last30 = nowInCT().minus({ days: 29 }).toISODate();
              const today = nowInCT().toISODate();
              changedAtRef.current!._flatpickr.setDate([last30, today], true);
            }}
          >
            30 Days
          </button>
          <button
            className="button is-ghost is-link is-paddingless has-text-weight-normal"
            onClick={() => {
              const last12mo = nowInCT().minus({ months: 12 }).toISODate();
              const today = nowInCT().toISODate();
              changedAtRef.current!._flatpickr.setDate([last12mo, today], true);
            }}
          >
            12 Mo.
          </button>
        </div>

        <span />

        <button
          className="button is-grid-row-span-2 is-align-self-flex-end"
          onClick={handleReset}
          disabled={isLoading}
        >
          Reset
        </button>

        <button
          className="button is-ghost is-link is-paddingless has-text-weight-normal"
          onClick={handleTotals}
        >
          Totals
        </button>

        {/* Row 2 */}

        <label htmlFor="series">Series:</label>
        <Multiselect
          menuClassName="is-z-index-2"
          name="series"
          onSelect={(value, checked) => {
            setSeries(
              checked ? [...series, value] : series.filter((s) => s !== value)
            );
          }}
          onSelectAll={() => {
            setSeries(series.length === uniqueCodes.length ? [] : uniqueCodes);
          }}
          options={uniqueCodes}
          values={series}
        />

        <label htmlFor="status" className="margin-left-1">
          Status:
        </label>
        <Multiselect
          menuClassName="is-z-index-2"
          name="status"
          labels={statusLabels}
          onSelect={(value, checked) => {
            setStatuses(
              checked
                ? [...statuses, value as DepartureDO_Status]
                : statuses.filter((s) => s !== value)
            );
          }}
          onSelectAll={() => {
            setStatuses(
              statuses.length === allStatuses.length ? [] : allStatuses
            );
          }}
          options={allStatuses}
          values={statuses}
        />

        <label htmlFor="departedAt" className="margin-left-1">
          Departure:
        </label>
        <input
          ref={departedAtRef}
          id="departedAt"
          type="date"
          className="input is-width-auto"
        />

        <label htmlFor="changedAt" className="margin-left-1">
          Changed:
        </label>
        <input
          ref={changedAtRef}
          id="changedAt"
          type="date"
          className="input is-width-auto"
        />

        <SVG
          className={clsx("margin-left-1", !isLoading && "is-invisible")}
          path="site/icon/spinner"
          alt="Spinner"
          height={18}
        />

        <button
          className="button is-yellow is-align-self-flex-end"
          onClick={handleSearch}
          disabled={isLoading}
        >
          Search
        </button>
      </div>

      <ErrorMessage className="margin-top-0 margin-bottom-3" errors={errors} />
    </>
  );
};

const departureShortcuts = () => {
  const now = nowInCT();

  const next7 = now.plus({ days: 6 }).toISODate();
  const today = now.toISODate();

  const next30 = now.plus({ days: 29 }).toISODate();

  const endOfYear = now.endOf("year").toISODate();

  const currentYear = now.year;
  const nextYear = currentYear + 1;

  return [
    [today, next7, "7 Days"],
    [today, next30, "30 Days"],
    [today, endOfYear, "EOY"],
    [`${currentYear}-01-01`, `${currentYear}-12-31`, currentYear.toString()],
    [`${nextYear}-01-01`, `${nextYear}-12-31`, nextYear.toString()],
  ];
};

interface CapacitySelectorProps {
  allowEmpty?: boolean;
  disabled: boolean;
  field: DepartureChangeDO_CapacityField;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  parentClassName?: string;
  range: number;
  rowIdx: number;
  value?: number;
}

const CapacitySelector: FC<CapacitySelectorProps> = ({
  allowEmpty = false,
  disabled,
  field,
  onChange,
  parentClassName,
  range,
  rowIdx,
  value,
}) => (
  <Select
    className="is-borderless is-square is-size-6 has-text-weight-normal is-height-auto padding-1"
    parentClassName={parentClassName}
    value={allowEmpty && value === undefined ? "" : value}
    onChange={onChange}
    disabled={disabled}
    data-row-idx={rowIdx}
    data-field={field}
  >
    {allowEmpty && <option value=""></option>}
    {arrOfSize(range).map((_, i, arr) => {
      const value = arr.length - i;
      return (
        <option key={value} value={value}>
          {value}
        </option>
      );
    })}
    <option value="0">0</option>
  </Select>
);

interface NoteHistoryProps {
  departedAt: string;
  isLocked: boolean;
  loginId: LoginDO_ID;
  notes: DepartureNoteDO[];
  onAdd: (note: DepartureNoteDO) => void;
  onEdit: (note: DepartureNoteDO) => void;
  series: string;
}

const NoteHistory: FC<NoteHistoryProps> = ({
  departedAt,
  isLocked,
  loginId,
  notes: initialNotes,
  onAdd,
  onEdit,
  series,
}) => {
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  // We need a local copy because updates to the "parent"
  // comp via onAdd/onEdit don't cause a re-render.
  const [notes, setNotes] = useState(initialNotes);

  const [body, setBody] = useState("");

  const [editNoteIdx, setEditNoteIdx] = useState(-1);
  const [editNoteBody, setEditNoteBody] = useState("");

  const handleNewNote = async () => {
    setIsLoading(true);

    catchErrors(
      async () => {
        const note = await makeDepartureNote({ series, departedAt, body });

        onAdd(note);

        setNotes([note, ...notes]);
        setBody("");
        if (editNoteIdx !== -1) setEditNoteIdx(editNoteIdx + 1);
      },
      () => setIsLoading(false)
    );
  };

  const handleEditMode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { idx } = e.currentTarget.dataset as { idx: string };
    setEditNoteIdx(+idx);
    setEditNoteBody(notes[+idx].body);
  };

  const handleNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditNoteBody(e.currentTarget.value);
  };

  const handleNoteSave = () => {
    setIsLoading(true);

    catchErrors(
      async () => {
        const note = notes[editNoteIdx];
        const { updatedAt } = await editDepartureNote({
          id: note.id,
          body: editNoteBody,
        });

        const updatedNote = { ...note, body: editNoteBody, updatedAt };
        onEdit(updatedNote);

        setNotes([updatedNote, ...notes.filter((n) => n.id !== note.id)]);
        setEditNoteIdx(-1);
        setEditNoteBody("");
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div
      className="is-grid is-grid-template-columns-auto-1fr-auto is-column-gap-1 is-row-gap-2 is-align-items-center margin-top-3"
      style={{ width: 555 }}
    >
      <span className="has-text-dark-gray margin-right-2">
        <DateAndInitials dateIso={new Date().toISOString()} loginId={loginId} />
      </span>
      <Input
        value={body}
        onChange={(e) => setBody(e.currentTarget.value)}
        placeholder="New note..."
        disabled={isLocked}
      />
      <button
        // The spinner is hard to see when an "is-blue" button is disabled
        className={clsx("button", !body || isLoading ? "is-gray" : "is-blue")}
        disabled={!body || isLoading}
        onClick={handleNewNote}
        style={{ minWidth: 55 }}
      >
        {isLoading ? (
          <SVG path="site/icon/spinner" alt="Spinner" height={18} />
        ) : (
          "Save"
        )}
      </button>
      <ErrorMessage
        className="is-marginless is-grid-column-2-neg-1"
        errors={errors}
      />
      {notes.map((note, idx) => (
        <Fragment key={note.id}>
          <span className="margin-right-2">
            <DateAndInitials dateIso={note.updatedAt} loginId={note.loginId} />
          </span>
          {note.loginId !== loginId || isLocked ? (
            <>
              <span>{note.body}</span>
              <span />
            </>
          ) : idx === editNoteIdx ? (
            <>
              <Input value={editNoteBody} onChange={handleNoteChange} />
              <button
                className={clsx(
                  "button",
                  !editNoteBody || isLoading ? "is-gray" : "is-blue"
                )}
                disabled={!editNoteBody || isLoading}
                onClick={handleNoteSave}
              >
                Save
              </button>
            </>
          ) : (
            <>
              <button
                className="has-background-white is-paddingless is-borderless has-text-left"
                onClick={handleEditMode}
                data-idx={idx}
              >
                {note.body}
              </button>
              <span />
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

interface ChangeLogProps {
  changes: DepartureChangeDO[];
}

const ChangeLog: FC<ChangeLogProps> = ({ changes }) => (
  <div
    className="is-grid is-column-gap-5 is-row-gap-1 margin-top-3"
    style={{ gridTemplateColumns: "repeat(4, auto)" }}
  >
    <strong>Change Date</strong>
    <strong>Change Item</strong>
    <strong>Change From</strong>
    <strong>Change To</strong>
    {changes.map((change) => (
      <Fragment key={change.id}>
        <span>
          <DateAndInitials
            dateIso={change.createdAt}
            loginId={change.loginId}
            showTime
          />
        </span>
        <span>{fieldLabel(change.field)}</span>
        <span>{titleCase(change.from, "-", "-")}</span>
        <span>{titleCase(change.to, "-", "-")}</span>
      </Fragment>
    ))}
  </div>
);

const fieldLabel = (field: DepartureChangeDO_Field) => {
  switch (field) {
    case "status":
      return "Status";
    case "roomCap":
      return "Rooms";
    case "singleMax":
      return "Singles";
    case "busMax":
      return "Coach Max";
    case "busSize":
      return "Coach Size";
    default:
      throw new Error(`Unexpected field ${field}`);
  }
};

interface TotalsProps {
  totals: AdminGetInventoryTotalsRes;
}

const Totals: FC<TotalsProps> = ({ totals }) => (
  <div
    className="is-inline-grid is-gap-1px has-background-gray has-border-gray"
    style={{ gridTemplateColumns: "repeat(5, auto)" }}
  >
    <strong className={`${whiteCell} ${centeredCell} is-grid-row-span-2`}>
      Series
    </strong>
    <strong className={`${whiteCell} has-text-centered is-grid-column-span-2`}>
      Sold
    </strong>
    <strong className={`${whiteCell} has-text-centered is-grid-column-span-2`}>
      Remaining
    </strong>

    <strong className={whiteCell}>Rms</strong>
    <strong className={whiteCell}>Pax</strong>
    <strong className={whiteCell}>Rms</strong>
    <strong className={whiteCell}>Pax</strong>

    {totals.map((row) => (
      <Fragment key={row.series}>
        <span className={whiteCell}>{row.series}</span>
        <span className={`${whiteCell} has-text-right`}>
          {row.occupancy.rooms}
        </span>
        <span className={`${whiteCell} has-text-right`}>
          {row.occupancy.pax}
        </span>
        <span className={`${whiteCell} has-text-right`}>
          {row.availability.rooms}
        </span>
        <span className={`${whiteCell} has-text-right`}>
          {row.availability.pax}
        </span>
      </Fragment>
    ))}
  </div>
);
